<template>
  <div>
    <custom-source-item :prevRoute="prevRoute" :id="id"></custom-source-item>
    <v-tabs v-if="canSeeChannels" v-model="tab">
      <v-tab :to="{ name: 'CustomSourceItemPage' }">Channels List</v-tab>
      <v-tab :to="{ name: 'CustomSourceItemLogs' }">Channel logs</v-tab>
    </v-tabs>
    <component
      v-if="canSeeChannels"
      :is="nestedPage"
      :sourceId="id"
    ></component>
  </div>
</template>

<script>
import ChannelList from "./channel/list/ChannelList";
import SourceLogList from "./sourceLog/list/SourceLogList";
import { SET_SOURCE_ID } from "@/store/modules/channel/actions";
import CustomSourceItem from "@/views/customSource/item/CustomSourceItem";
import acl from "@/mixin/acl";
import { RESOURCE_CHANNELS } from "@/store/modules/admin/getters";
export default {
  name: "CustomSourcePage",
  mixins: [acl],
  props: {
    id: {
      required: true,
    },
  },
  components: {
    CustomSourceItem,
    ChannelList,
    SourceLogList,
  },
  data() {
    return {
      prevRoute: null,
      tab: null,
      items: { sourceItem: CustomSourceItem },
    };
  },
  computed: {
    canSeeChannels: function () {
      return this.hasAccess([RESOURCE_CHANNELS]);
    },
    nestedPage: function () {
      return this.$route.meta.nestedComponent;
    },
  },
  beforeMount() {
    this.$store.dispatch(`channels/${SET_SOURCE_ID}`, this.id);
    // this.$store.dispatch(`tag/${FETCH_TAGS}`);
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "CustomSourceListPage") {
      next((vm) => {
        vm.prevRoute = from;
      });
    } else {
      next();
    }
  },
};
</script>
