<template>
  <v-container fluid>
    <div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-overlay v-if="deleteInProgress">
            <v-progress-circular
              indeterminate
              width="2"
              color="primary"
            ></v-progress-circular>
          </v-overlay>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDelete">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row style="max-height: 75px">
      <v-icon v-if="editedItem.isActive" color="green" class="mx-2 mb-2" small>
        mdi-check-circle-outline
      </v-icon>
      <v-icon v-else color="grey" class="mx-2 mb-2" small>
        mdi-close-circle-outline
      </v-icon>
      <span class="mt-8">
        <v-img v-bind:src="sourceItem.img" alt="" height="20px" width="20px" />
      </span>
      <v-col cols="2">
        <v-text-field
          class="font-weight-black"
          flat
          validate-on-blur
          :loading="isLoading"
          :disabled="!enableEditName"
          :rules="[inputRequired, maxCharsRule]"
          v-model="editedItem.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        class="my-0 py-0"
        color="primary"
        depressed
        text
        small
        tile
        plain
        @click="backToSources"
      >
        <v-icon>mdi-arrow-left-thin</v-icon>Back to sources
      </v-btn>
      <v-spacer></v-spacer>
      <v-icon color="primary" class="mx-2">mdi-account</v-icon>
      <p class="my-0 py-1" style="color: darkslategrey">
        ID: {{ sourceItem.accountId }} | Name: {{ sourceItem.accountName }}
      </p>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12" lg="3" md="6">
        <v-card max-width="340" outlined tile :loading="isLoading" class="pa-4">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="9">Id</v-col>
              <v-col class="ml-5">{{ sourceItem.id }}</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9" class="my-5"> Enabled </v-col>
              <v-col>
                <v-switch
                  class="ml-5"
                  color="#67bb6a"
                  dense
                  v-model="editedItem.isActive"
                  disabled
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col v-if="!sourceItem.isSocial" cols="6" class="mt-2"
                >Parsing Group</v-col
              >
              <v-col
                v-if="!sourceItem.isSocial"
                cols="3"
                class="mt-2 pl-7 text-end text-truncate"
              >
                {{ parsingGroupTitle }}
              </v-col>
              <v-col cols="1">
                <v-menu v-if="!sourceItem.isSocial" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-5"
                      color="grey"
                      fab
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group v-if="canManage">
                      <v-list-item
                        v-for="item in parsingGroups"
                        @click="editParsingGroup(item.value)"
                        :key="item"
                        :value="item"
                      >
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pa-0">
            <v-row>
              <v-col v-if="enableEditRating" cols="7" class="mt-2"
                ><p>Domain rating</p></v-col
              >
              <v-col v-else cols="9" class="mt-2"><p>Domain rating</p></v-col>
              <v-col v-if="!enableEditRating">
                <v-btn v-if="canManage" color="grey" fab icon small>
                  <v-icon @click="enableEditRating = true"
                    >mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col class="pt-7" v-else>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#67bb6a"
                    small
                    outlined
                    @click="editRating"
                    class="mr-2"
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                  <v-btn
                    color="grey darken-1"
                    small
                    outlined
                    @click="closeEditRating"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pt-0">
                <v-text-field
                  class="pt-0 mt-0"
                  :disabled="!enableEditRating"
                  :rules="[isFloatRule]"
                  v-model="editedItem.domainRating"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="9" class="mt-2"><p>Source Tags</p></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-chip-group
                  v-if="!enableEditTags"
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    small
                    v-for="item in sourceItem.relatedTagsList"
                    :key="item"
                    >{{ item }}</v-chip
                  >
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <v-card
          outlined
          tile
          :loading="isLoading"
          :ripple="false"
          style="cursor: default"
        >
          <v-row>
            <v-col class="ma-0">
              <v-list flat disabled>
                <v-list-item-group>
                  <v-list-item dense>
                    <v-card-text class="pa-0" disabled>Created</v-card-text>
                    <v-card-text class="pa-0">{{
                      sourceItem.createdAt
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Updated</v-card-text>
                    <v-card-text class="pa-0">{{
                      sourceItem.updatedAt
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Enabled Changed</v-card-text>
                    <v-card-text class="pa-0"
                      >{{ sourceItem.enabledChangedAt }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Enabled Changed By</v-card-text>
                    <v-card-text class="pa-0"
                      >{{ sourceItem.enabledChangedBy }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense style="cursor: default">
                    <v-card-text class="pa-0">Channels</v-card-text>
                    <v-card-text
                      class="pa-0"
                      style="color: dodgerblue"
                      disabled
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="green--text">{{
                            sourceItem.activeChannelsCount
                          }}</span>
                        </template>
                        Active channels </v-tooltip
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            disabled
                            class="black--text"
                            >/{{ sourceItem.channelsCount }}</span
                          ></template
                        >All channels</v-tooltip
                      >
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0" disabled
                      >Active Projects</v-card-text
                    >
                    <v-card-text class="pa-0">{{
                      sourceItem.activeProjectsCnt
                    }}</v-card-text>
                  </v-list-item>
                </v-list-item-group>
                <v-divider class="my-6"></v-divider>
                <v-list-item-group>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Last Run</v-card-text>
                    <v-card-text class="pa-0">{{
                      sourceItem.lastParsing
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="mb-1 pa-0">Next Run</v-card-text>
                    <v-card-text class="pa-0">{{
                      sourceItem.nextParsing
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="mb-1 pa-0">
                      Grace period for Warning
                    </v-card-text>
                    <v-card-text class="pa-0"
                      >{{ selectedParsingGroup?.gracePeriodWarn ?? "NA" }} days
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="mb-1 pa-0">
                      Grace period for Error:
                    </v-card-text>
                    <v-card-text class="pa-0"
                      >{{ selectedParsingGroup?.gracePeriodErr ?? "NA" }} days
                    </v-card-text>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="3" sm="3">
              <v-list flat disabled dense>
                <v-list-item dense>
                  <v-card-subtitle class="pa-0"
                    >Source Parsing:</v-card-subtitle
                  >
                </v-list-item>
                <v-list-item-group v-if="!sourceItem.isSocial">
                  <v-list-item dense>
                    <v-list-item-content>
                      <span class="green--text">
                        Success:
                        {{ sourceItem.sourceParsingStatus.success ?? 0 }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-content>
                      <span class="orange--text">
                        Warning:
                        {{ sourceItem.sourceParsingStatus.warning ?? 0 }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-content>
                      <span class="red--text">
                        Error:
                        {{ sourceItem.sourceParsingStatus.error ?? 0 }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-else>
                  <v-list-item dense>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" disabled>
                          <v-icon
                            v-if="
                              !sourceItem.formattedSourceParsingStatus ||
                              sourceItem.isSocial
                            "
                          >
                          </v-icon>
                          <v-icon
                            v-else-if="
                              sourceItem.formattedSourceParsingStatus ===
                              'warning'
                            "
                            color="yellow"
                            class="mr-5 pa-0"
                          >
                            mdi-alert-outline
                          </v-icon>
                          <v-icon
                            v-else-if="
                              sourceItem.formattedSourceParsingStatus ===
                              'error'
                            "
                            color="red"
                            class="mr-5 pa-0"
                          >
                            mdi-alert-box-outline
                          </v-icon>
                          <v-icon v-else color="green" class="mr-5 pa-0">
                            mdi-check-circle-outline
                          </v-icon>
                        </span></template
                      ><span v-if="sourceItem.formattedSourceParsingStatus">
                        {{
                          sourceItem.formattedSourceParsingStatus.toUpperCase()
                        }}
                      </span>
                    </v-tooltip>
                    <span v-if="sourceItem.isSocial">
                      <v-card-text class="pa-0 px-10 grey--text"
                        >NA</v-card-text
                      >
                    </span>
                    <span
                      v-else-if="
                        sourceItem.formattedSourceParsingStatus &&
                        sourceItem.formattedSourceParsingStatus === 'error'
                      "
                    >
                      <v-card-text class="pa-0" style="color: red"
                        >Error</v-card-text
                      >
                    </span>
                    <span
                      v-else-if="
                        sourceItem.formattedSourceParsingStatus &&
                        sourceItem.formattedSourceParsingStatus === 'warning'
                      "
                    >
                      <v-card-text class="pa-0" style="color: orange"
                        >Warning</v-card-text
                      >
                    </span>
                    <span v-else-if="sourceItem.formattedSourceParsingStatus">
                      <v-card-text class="pa-0" style="color: green"
                        >Success</v-card-text
                      >
                    </span>
                    <span v-else></span>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="3" sm="3">
              <v-list flat disabled>
                <v-list-item dense>
                  <v-card-subtitle class="pa-0"
                    >Article Parsing:</v-card-subtitle
                  >
                </v-list-item>
                <v-list-item-group>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Success</v-card-text>
                    <v-card-text class="ml-8 pa-0" style="color: green">{{
                      sourceItem.lastParsed
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Error</v-card-text>
                    <v-card-text class="ml-8 pa-0" style="color: red">{{
                      sourceItem.lastErrors
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Rate</v-card-text>
                    <v-card-text class="ml-8 pa-0">{{
                      sourceItem.articleParsingRate
                    }}</v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Article</v-card-text>
                    <v-card-text class="ml-8 pa-0">{{
                      sourceItem.totalParsed
                    }}</v-card-text>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col col="1">
        <v-btn
          color="red"
          outlined
          small
          class="mr-2"
          v-if="canManage"
          @click="deleteItem"
          ><v-icon>mdi-delete-forever-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import {
  FETCH_CUSTOM_SOURCE,
  UPDATE_CUSTOM_SOURCE,
  DELETE_CUSTOM_SOURCE,
} from "@/store/modules/customSource/actions";
import acl from "@/mixin/acl";
import { RESOURCE_CUSTOM_SOURCES_MANAGEMENT } from "@/store/modules/admin/getters";
import {
  FETCH_PARSING_GROUP,
  FETCH_PARSING_GROUPS_DICTIONARY,
} from "@/store/modules/parsingGroup/actions";
export default {
  name: "CustomSourceItem",
  mixins: [acl],
  props: {
    id: {
      required: true,
    },
    prevRoute: {
      required: true,
    },
  },
  inheritAttrs: true,
  data() {
    return {
      isLoading: true,
      enableEditName: false,
      enableEditTags: false,
      enableEditRating: false,
      dialogDelete: false,
      deleteInProgress: false,
      tagItems: [],
      editedItem: {
        name: null,
        domainRating: null,
        relatedTagsList: [],
        parsingGroup: null,
        isActive: null,
      },
      defaultItem: {
        name: null,
        domainRating: null,
        relatedTagsList: [],
        parsingGroup: null,
        isActive: null,
      },
      parsingGroups: [],
      selectedParsingGroup: null,
      errorMsg: "",
      inputRequired: (v) => !!v || "Required field",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      tagsRequired: (v) => !!v.length || "Required field",
      isFloatRule: (v) =>
        !v || /^-?\d+(\.\d+)?$/.test(v) || v === "-" || "Digit required",
      maxTagCharsRule: (v) => {
        const tooLongItems = v.filter(function (item) {
          return item.length > 40;
        });

        return tooLongItems.length === 0 || "Input too long!";
      },
    };
  },
  computed: {
    ...mapGetters("customSource", ["sourceItem"]),
    ...mapGetters("tag", ["tagList"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_CUSTOM_SOURCES_MANAGEMENT]);
    },
    parsingGroupTitle: function () {
      let title = "-";
      const vm = this;
      this.parsingGroups.forEach(function (item) {
        if (item.value === vm.sourceItem.parsingGroup) {
          title = item.title;
          return;
        }
      });
      return title;
    },
  },
  watch: {
    "editedItem.enable": {
      handler: function () {
        // this.editItem();
      },
      deep: true,
    },
    tagList() {
      this.tagItems = _.merge([], this.tagList);
    },
  },
  methods: {
    backToSources() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);
      } else {
        this.$router.go(-1);
      }
    },
    fetchItem: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`customSource/${FETCH_CUSTOM_SOURCE}`, this.id).then(
        () => {
          vm.isLoading = false;
          this.showPages = true;
          let { name, parsingGroup, domainRating, relatedTagsList, isActive } = vm.sourceItem;
          let objItem = {
            name: name,
            domainRating: domainRating,
            parsingGroup: parsingGroup,
            relatedTagsList: relatedTagsList,
            isActive: isActive,
          };
          this.editedItem = _.merge({}, objItem);
          this.defaultItem = _.merge({}, objItem);
          this.fetchSelectedParsingGroup();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    editParsingGroup: function (v) {
      this.editedItem.parsingGroup = v;
      this.editItem();
    },
    editItem: function () {
      let vm = this;
      let { editedItem } = this;
      this.$store
        .dispatch(`customSource/${UPDATE_CUSTOM_SOURCE}`, {
          id: vm.id,
          editedItem,
        })
        .then(
          function () {
            vm.fetchItem();
            // vm.editedItem = _.merge({}, vm.sourceItem);
          },
          function (error) {
            vm.errorMsg = error;
          }
        )
        .catch(() => {
          this.errorMsg = "Something went wrong";
        });
    },
    closeEditRating: function () {
      this.enableEditRating = false;
      this.editedItem = _.merge({}, this.defaultItem);
    },
    editRating: function () {
      this.enableEditRating = false;
      this.editItem();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    deleteItemConfirm: function () {
      const vm = this;
      const { id } = this;
      vm.deleteInProgress = true;
      this.$store
        .dispatch(`customSource/${DELETE_CUSTOM_SOURCE}`, { id })
        .then(
          function () {
            vm.deleteInProgress = false;
            vm.$router.push({
              name: "CustomSourceListPage",
            });
          },
          function (error) {
            vm.deleteInProgress = false;
            vm.errorMsg = error;
          }
        )
        .catch(() => {
          vm.deleteInProgress = false;
          this.errorMsg = "Something went wrong";
        });
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    fetchParsingGroups: function () {
      this.$store
        .dispatch("parsingGroup/" + FETCH_PARSING_GROUPS_DICTIONARY)
        .then(
          (payload) => {
            this.parsingGroups = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
    fetchSelectedParsingGroup: function () {
      this.$store
        .dispatch("parsingGroup/" + FETCH_PARSING_GROUP, {
          id: this.sourceItem.parsingGroup,
        })
        .then(
          (payload) => {
            this.selectedParsingGroup = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  beforeMount() {
    this.fetchParsingGroups();
    this.fetchItem();
  },
  created() {
    this.tagItems = _.merge([], this.tagList);
  },
};
</script>
